import { FC, useMemo, useRef, useState } from 'react';
import { Button, SlideOver } from '@gloabal-regulatory-writing-consulting/gxt-components';
import EditCatalogTemplateForm from './EditCatalogTemplateForm';
import { CatalogTemplateFormProps, EditCatalogTemplateProps } from './EditCatalogTemplate.types';
import { getGroupsByProjectIds, useCatalog, useMappingSessionAPI } from '../../../../services/api';
import { DocumentTabs } from '../../../../types';
import useModal from '../../../../hooks/useModal';
import { CheckboxSelectionSlideOver } from '../../../../components/CheckboxSelectionSlideOver';
import { useQuery } from '@tanstack/react-query';
import { getProjects } from '../../../../services/api/projectSettings';
import { InProgressEditValues, TargetEditValues } from '../CatalogTable/CatalogTable.types';
import { getAllCatalogItems } from '../../../settings/components/utils/apiHelpers';
import { ItemType } from '../../../../services/api/types';

const EditCatalogTemplates: FC<EditCatalogTemplateProps> = ({
  isOpen,
  onClose,
  activeTab,
  defaultValues,
}) => {
  const formRef = useRef<{ submitForm: () => void }>(null);
  const [formData, setFormData] = useState<CatalogTemplateFormProps>({
    ...defaultValues,
    groups: defaultValues.groups || [],
    sections: defaultValues.sections || [],
    project: defaultValues.project || [],
  });

  const { editMappingSession } = useMappingSessionAPI();
  const { editCatalog } = useCatalog();

  const projectSlideOver = useModal();
  const groupsSlideOver = useModal();
  const sectionsSlideOver = useModal();

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleSubmitData = async (_data: TargetEditValues) => {
    const data = {
      groups: formData?.groups?.map((group) => group.value) || [],
      sections: formData?.sections?.map((section) => section.value) || [],
    };

    if (defaultValues.id) {
      await editCatalog.mutateAsync({ id: +defaultValues.id, data });
    }
    onClose();
  };

  const handleAutomatedSubmitData = async (data: TargetEditValues) => {
    const sessionData = {
      id: defaultValues?.id,
      title: data.templateName || '',
      version: data.version ?? '1.0',
      groups: formData?.groups?.map((group) => group.value) || [],
      sections: formData?.sections?.map((section) => section.value) || [],
    };

    await editMappingSession.mutateAsync(sessionData);
    onClose();
  };

  const handleInProgressSubmitData = async (data: InProgressEditValues) => {
    const sessionData = {
      id: defaultValues.id,
      title: data.title || '',
      projectId: formData?.project?.[0]?.value,
      sections: formData?.sections?.map((section) => section.value) || [],
    };

    await editMappingSession.mutateAsync(sessionData);
    onClose();
  };

  const editTabProperties: Record<
    DocumentTabs,
    { heading: string; submitData: (data: any) => void }
  > = {
    [DocumentTabs.AUTOMATED]: {
      heading: 'Edit Automated Template Properties',
      submitData: handleAutomatedSubmitData,
    },
    [DocumentTabs.TARGET]: {
      heading: 'Edit Target Template Properties',
      submitData: handleSubmitData,
    },
    [DocumentTabs.SOURCE]: {
      heading: 'Edit Source Properties',
      submitData: handleSubmitData,
    },
    [DocumentTabs.IN_PROGRESS]: {
      heading: 'Edit In-Progress Document Properties',
      submitData: handleInProgressSubmitData,
    },
    [DocumentTabs.PRE_AUTHORED]: {
      heading: 'Edit Pre-Authored Document Properties',
      submitData: (_data: any) => {},
    },
    [DocumentTabs.COMPLETED]: {
      heading: 'Edit Completed Document Properties',
      submitData: (_data: any) => {},
    },
  };

  const displayProjectSlideOver = activeTab === DocumentTabs.IN_PROGRESS;

  const { data: projectResponse } = useQuery({
    queryKey: ['catalog-edit-projects'],
    queryFn: () => getProjects({}),
    enabled: activeTab === DocumentTabs.IN_PROGRESS,
  });

  const { data: groupsResponse } = useQuery({
    queryKey: ['groups', formData.project, activeTab],
    queryFn: () => getGroupsByProjectIds(formData?.project?.map((project) => +project.value) || []),
    enabled: activeTab === DocumentTabs.IN_PROGRESS ? (formData?.project?.length || 0) > 0 : true,
  });

  const { data: sectionsResponse } = useQuery({
    queryKey: [ItemType.Section],
    queryFn: () => getAllCatalogItems('Section'),
  });

  const projectsData = useMemo(
    () =>
      (projectResponse?.data.data || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [projectResponse],
  );

  const groupsData = useMemo(
    () =>
      (groupsResponse?.data.groups || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [groupsResponse],
  );

  const sectionsData = useMemo(
    () =>
      (sectionsResponse || []).map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      })),
    [sectionsResponse],
  );

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} mountElementId="styled-wrapper" width="28rem">
      <SlideOver.Header className="flex p-6 flex-col items-start gap-2 self-stretch bg-primary-50 text-system-50">
        {editTabProperties[activeTab].heading}
      </SlideOver.Header>
      <EditCatalogTemplateForm
        ref={formRef}
        handleSubmitData={editTabProperties[activeTab].submitData}
        activeTab={activeTab}
        defaultValues={formData}
        openProjectSlideOver={projectSlideOver.openModal}
        openGroupsSlideOver={groupsSlideOver.openModal}
        openSectionsSlideOver={sectionsSlideOver.openModal}
        setFormData={setFormData}
      />
      {displayProjectSlideOver && projectSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={projectSlideOver.show}
          handleClose={projectSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, project: value })}
          options={projectsData}
          selectedOptions={formData.project}
          displayBackButton
          title="Projects"
          multiple={false}
        />
      )}
      {groupsSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={groupsSlideOver.show}
          handleClose={groupsSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, groups: value })}
          options={groupsData}
          selectedOptions={formData.groups}
          displayBackButton
          title="Groups"
        />
      )}
      {sectionsSlideOver.show && (
        <CheckboxSelectionSlideOver
          isOpen={sectionsSlideOver.show}
          handleClose={sectionsSlideOver.closeModal}
          onSave={(value) => setFormData({ ...formData, sections: value })}
          options={sectionsData}
          selectedOptions={formData.sections}
          displayBackButton
          title="Sections"
        />
      )}
      <SlideOver.Footer className="flex flex-col items-center self-stretch">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={handleFormSubmit}>
            Save
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default EditCatalogTemplates;
