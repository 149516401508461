// React-related imports
import { FC, useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { PaginatedTable } from '../../../../components/PaginatedTable';
import TinyMceModal from '../Modal/TinyMceModal/TinyMceModal';
import { DeleteConfirmationDialogue } from '../../../../components/modals/DeleteConfirmationDialogue';
import { EditCatalogTemplates } from '../EditCatalogTemplates';
import ConvertToAutoMappingModal from '../../../../components/elements/ConvertToAutoMappingModal';
import SvgIcon from '../../../../components/elements/SvgIcon';
import { Dropdown } from '@gloabal-regulatory-writing-consulting/gxt-components';

// API services
import {
  getCatalogs,
  getMappingSessions,
  useCatalog,
  useMappingSessionAPI,
} from '../../../../services/api';

// Types
import { CatalogEditValues, CatalogTableProps } from './CatalogTable.types';
import { DocumentTabs, MappingSessionRecord } from '../../../../types';
import { MappingSession } from '../../../../services/api/types';
import { CatalogItemsHash } from '../../../upload';

// Utility functions
import { generateColumnsByTab } from './utils/catalogColumns';
import { AddSourcesPreviousAction } from '../../util/constants';
import { getDropdownOptions } from '../../../../helpers/utils';
import { twMerge } from 'tailwind-merge';

// Other utility components
import generateMenuItems from './utils/menuItems';
import { createOptionValues, isDropdownDisabled } from './utils/helper';

// Hooks
import useModal from '../../../../hooks/useModal';
import CopyDocumentSlideOver from '../CopyDocumentSlideOver/CopyDocumentSlideOver';

const CatalogTable: FC<CatalogTableProps> = ({
  activeTab,
  paginatedFilteredData,
  handleColumnSort,
  templateType,
  handleSelectAll = () => {},
  handleSelect = () => {},
  handlePageChange = (_page: number) => {},
  handlePerPageChange = (_perPage: number) => {},
  selectedRecords,
}) => {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isDocInteractive, setDocInteractive] = useState(true);
  const [selectedSession, setSelectedSession] = useState<Partial<MappingSessionRecord>>({});
  const [defaultFormValues, setDefaultFormValues] = useState<
    Record<DocumentTabs, CatalogEditValues>
  >({
    Automated: { templateName: '', version: '1.0', id: 0, sections: [], groups: [] },
    Target: { id: 0, sections: [], groups: [] },
    Source: { id: 0, groups: [] },
    'In-Progress': {
      title: '',
      project: [],
      sections: [],
      id: 0,
    },
    'Pre-Authored': { templateName: '', version: '1.0', id: 0, sections: [] },
    Completed: { templateName: '', version: '1.0', id: 0, sections: [] },
  });

  const navigate = useNavigate();

  const catalogEdit = useModal();
  const deleteModal = useModal();
  const previewModal = useModal();
  const convertToAutomatedModal = useModal();
  const copyDocumentModal = useModal();

  const { deleteCatalog } = useCatalog();
  const { archiveSession } = useMappingSessionAPI();

  const sortedColumn = useMemo(
    () => ({
      column: paginatedFilteredData.sort?.column || '',
      order: paginatedFilteredData.sort?.type || 'DESC',
    }),
    [paginatedFilteredData.sort?.column, paginatedFilteredData.sort?.type],
  );

  const activeTabColumns = generateColumnsByTab(
    activeTab === DocumentTabs.TARGET && templateType === 'Automated'
      ? DocumentTabs.AUTOMATED
      : activeTab,
    handleColumnSort,
    sortedColumn,
  );

  const handleCatalogDelete = async () => {
    await deleteCatalog.mutateAsync([selectedId]);
    closeDeleteModal();
  };

  const handleSessionDelete = async () => {
    await archiveSession.mutateAsync(selectedId);
    closeDeleteModal();
  };

  const setPreviewDocId = useCallback((id: number) => {
    setSelectedId(id);
  }, []);

  const handlePreviewDocument = useCallback(
    (id: number, documentType: boolean) => {
      setPreviewDocId(id);
      previewModal.openModal();
      setDocInteractive(documentType);
    },
    [setPreviewDocId],
  );

  const getFilteredPaginatedCatalogs = () => {
    return getCatalogs(paginatedFilteredData);
  };

  const getFilteredPaginatedMappingSessions = () => {
    return getMappingSessions<MappingSession>(paginatedFilteredData);
  };

  const setValues = (values: CatalogEditValues) => {
    setDefaultFormValues((prev) => ({
      ...prev,
      [selectedTab]: {
        ...prev[selectedTab],
        ...values,
      },
    }));
  };

  const handleAutomatedEdit = (row: {
    original: {
      title: string;
      version: string;
      id: number;
      catalogItems: CatalogItemsHash;
    };
  }) => {
    setValues({
      templateName: row.original.title || '',
      version: row.original.version || '1.0',
      id: row.original.id,
      sections: createOptionValues(row.original.catalogItems.Section),
      groups: createOptionValues(row.original.catalogItems.Group),
    });
    catalogEdit.openModal();
  };

  const handleCatalogEdit = (row: {
    original: {
      id: number;
      catalogItems: CatalogItemsHash;
    };
  }) => {
    setValues({
      id: row.original.id,
      sections: createOptionValues(row.original.catalogItems.Section),
      groups: createOptionValues(row.original.catalogItems.Group),
    });
    catalogEdit.openModal();
  };

  const handleInProgressEdit = (row: { original: MappingSession }) => {
    setValues({
      title: row.original.title || '',
      project: row.original.project
        ? [
            {
              label: row.original.project.name,
              value: row.original.project.id.toString(),
            },
          ]
        : [],
      sections: createOptionValues(row.original.catalogItems.Section),
      id: row.original.id,
    });
    catalogEdit.openModal();
  };

  const startAuthoringOnClick = (id: string) => {
    navigate('/add-source', {
      state: {
        sessionId: id,
        templateType,
        previousAction: AddSourcesPreviousAction.AUTHORING_REQUEST,
      },
    });
  };

  const handleAuthor = (id: string) => {
    navigate(`/mapping-sessions/${id}`);
  };

  const handleVerify = (id: string) => {
    navigate(`/mapping-sessions/${id}/dv`);
  };

  const handleConvert = (id: number, title: string) => {
    convertToAutomatedModal.openModal();
    setSelectedSession({ id, title });
  };

  const handleCopy = (row: any) => {
    setSelectedSession(row.original);
    copyDocumentModal.openModal();
  };

  const handleDeleteClick = (id: string) => {
    setSelectedId(Number(id));
    deleteModal.openModal();
  };

  const closeDeleteModal = () => {
    deleteModal.closeModal();
    setSelectedId(0);
  };

  const selectedTab =
    activeTab === DocumentTabs.TARGET ? (templateType as DocumentTabs) : activeTab;

  const tabValues: Record<
    DocumentTabs,
    {
      apiCall: () => any;
      deleteApiCall: () => Promise<void>;
      values: CatalogEditValues;
      handleEdit: (row: any) => void;
    }
  > = {
    [DocumentTabs.TARGET]: {
      apiCall: getFilteredPaginatedCatalogs,
      deleteApiCall: handleCatalogDelete,
      values: defaultFormValues[selectedTab],
      handleEdit: handleCatalogEdit,
    },
    [DocumentTabs.AUTOMATED]: {
      apiCall: getFilteredPaginatedMappingSessions,
      deleteApiCall: handleSessionDelete,
      values: defaultFormValues[selectedTab],
      handleEdit: handleAutomatedEdit,
    },
    [DocumentTabs.SOURCE]: {
      apiCall: getFilteredPaginatedCatalogs,
      deleteApiCall: handleCatalogDelete,
      values: defaultFormValues[selectedTab],
      handleEdit: handleCatalogEdit,
    },
    [DocumentTabs.IN_PROGRESS]: {
      apiCall: getFilteredPaginatedMappingSessions,
      deleteApiCall: handleSessionDelete,
      values: defaultFormValues[selectedTab],
      handleEdit: handleInProgressEdit,
    },
    [DocumentTabs.COMPLETED]: {
      apiCall: getFilteredPaginatedMappingSessions,
      deleteApiCall: handleSessionDelete,
      values: defaultFormValues[selectedTab],
      handleEdit: handleAutomatedEdit,
    },
    [DocumentTabs.PRE_AUTHORED]: {
      apiCall: getFilteredPaginatedMappingSessions,
      deleteApiCall: handleSessionDelete,
      values: defaultFormValues[selectedTab],
      handleEdit: handleAutomatedEdit,
    },
  };

  const menuItems = useMemo(
    () =>
      generateMenuItems(
        activeTab,
        handleDeleteClick,
        handlePreviewDocument,
        tabValues[selectedTab].handleEdit,
        startAuthoringOnClick,
        handleAuthor,
        handleVerify,
        handleConvert,
        handleCopy,
      ),
    [activeTab, templateType],
  );

  // useEffect(() => {
  // TODO - Fix Params
  // updateParams({ basePath: 'catalog-list', filters: paginatedFilteredData.filters, activeTab });
  // }, [activeTab, paginatedFilteredData.filters]);

  const renderDropdown = ({ row }: { row: any }) => {
    const isDisabled = isDropdownDisabled(selectedRecords, activeTab, String(row.original.id));
    return (
      <div className="space-x-2">
        <Dropdown
          options={getDropdownOptions(menuItems).map((item) => {
            if (item.value.title === 'Start Authoring') {
              return {
                ...item,
                disabled: isDisabled,
              };
            }
            return item;
          })}
          type="button"
          placeholder={<SvgIcon iconType="button-dots" />}
          position="center"
          renderOption={(option) => {
            if (!option) return null;

            const { title, onClick, className } = option.value;

            return (
              <span
                className={twMerge(`py-2 px-4 ${className}`)}
                onClick={option.disabled ? () => {} : () => onClick(row.original.id, row)}>
                {title}
              </span>
            );
          }}
          customStyles={{
            placeholder: {
              padding: '0.125rem 0.75rem',
            },
            // TODO: remove this after converting all tables to new design
            itemsWrapper: { zIndex: 12 },
            item: {
              display: 'flex',
              justifyContent: 'center',
              padding: '0',
            },
          }}
        />
      </div>
    );
  };

  return (
    <>
      <PaginatedTable
        additionalColumns={activeTabColumns}
        paginatedFilteredData={paginatedFilteredData}
        getData={tabValues[selectedTab].apiCall}
        menuItems={menuItems}
        handleSelectAll={handleSelectAll}
        onSelect={handleSelect}
        selectedData={selectedRecords}
        handlePageChange={handlePageChange}
        handlePerPageChange={handlePerPageChange}
        queryKey={['Catalog', activeTab, templateType]}
        actionsColumn
        renderDropdown={renderDropdown}
      />
      <DeleteConfirmationDialogue
        isOpen={deleteModal.show}
        handleClose={closeDeleteModal}
        handleDelete={tabValues[selectedTab].deleteApiCall}
      />
      {activeTab === DocumentTabs.SOURCE && previewModal.show && (
        <TinyMceModal
          closeEditorModalOpen={previewModal.closeModal}
          editorModalOpen={previewModal.show}
          key="catalog_preview"
          editorType="Source"
          catalogId={selectedId}
          isFileInteractive={isDocInteractive}
        />
      )}
      {catalogEdit.show && (
        <EditCatalogTemplates
          isOpen={catalogEdit.show}
          onClose={catalogEdit.closeModal}
          activeTab={selectedTab}
          defaultValues={tabValues[selectedTab].values}
        />
      )}
      {copyDocumentModal.show && (
        <CopyDocumentSlideOver
          isOpen={copyDocumentModal.show}
          sessionData={selectedSession as MappingSessionRecord}
          handleClose={copyDocumentModal.closeModal}
          onStartAuthoring={handleAuthor}
        />
      )}
      {activeTab === DocumentTabs.IN_PROGRESS && convertToAutomatedModal.show && (
        <ConvertToAutoMappingModal
          show={convertToAutomatedModal.show}
          handleClose={convertToAutomatedModal.closeModal}
          mappingSessionData={selectedSession as MappingSessionRecord}
        />
      )}
    </>
  );
};

export default CatalogTable;
