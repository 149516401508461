import { MenuItemType } from '../../../../../components/PaginatedTable/PaginatedTable.types';
import { DocumentTabs } from '../../../../../types';
import { CatalogItemsHash } from '../../../../upload/UploadSource/UploadSource.type';

const generateMenuItems = (
  activeTab: DocumentTabs,
  handleDelete: (id: string) => void,
  handlePreviewDocument: (id: number, documentType: boolean) => void,
  handleEdit: (row: {
    original: { title: string; version: string; id: number; catalogItems: CatalogItemsHash };
  }) => void,
  startAuthoringOnClick: (id: string) => void,
  handleAuthor: (id: string) => void,
  handleVerify: (id: string) => void,
  handleConvert: (id: number, title: string) => void,
  handleCopy: (row: any) => void,
): MenuItemType<any>[] => {
  const commonItems: MenuItemType<any>[] = [
    {
      title: 'Edit',
      onClick: (id: string, row) => {
        handleEdit(row);
      },
    },
    {
      title: 'Delete',
      onClick: handleDelete,
      className: 'text-negative-50 hover:text-negative-100',
    },
  ];

  switch (activeTab) {
    case DocumentTabs.TARGET:
      return [
        {
          title: 'Start Authoring',
          onClick: (id: string) => startAuthoringOnClick(id),
        },
        ...commonItems,
      ];
    case DocumentTabs.SOURCE:
      return [
        {
          title: 'View',
          onClick: (
            _id: string,
            row: {
              original: {
                isInteractive: boolean;
                id: number;
                nonParsedCatalogId: number;
                baseTargetFileId?: number;
              };
            },
          ) => {
            const catalogId = row.original?.isInteractive
              ? row.original.id
              : row.original.nonParsedCatalogId;
            const documentId = row.original?.baseTargetFileId || catalogId;
            const documentType = row.original?.baseTargetFileId ?? row.original.isInteractive;
            handlePreviewDocument(documentId, !!documentType);
          },
        },
        ...commonItems,
      ];
    case DocumentTabs.IN_PROGRESS:
    case DocumentTabs.COMPLETED:
      return [
        {
          title: 'Author',
          onClick: handleAuthor,
        },
        {
          title: 'Verify',
          onClick: handleVerify,
        },
        {
          title: 'Copy',
          onClick: (_id, row) => handleCopy(row),
        },
        {
          title: 'Convert',
          onClick: (id, row) => handleConvert(+id, row.original.title),
        },
        ...commonItems,
      ];
    default:
      return [];
  }
};

export default generateMenuItems;
