import { z } from 'zod';

export const copyDocumentSchema = z.object({
  title: z.string().min(1),
  project: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Project must have at least one element' })
    .max(1, { message: 'Project must have at most one element' }),
  sections: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Section must have at least one element' }),
  version: z.string().superRefine((value, ctx) => {
    // Should allow whole number versions
    if (parseFloat(value) % 1 === 0) {
      return;
    }
    // Validate the version format to not allow more than 3 digits before and 1 digit after the decimal point
    const regex = /^\d{1,3}\.\d$/;
    if (!regex.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          'Version should have up to 3 digits before and up to 1 digit after the decimal point (e.g., 1.0)',
      });
    }
  }),
});
